<template>
<div>

   
     
        <!-- <div class="d-flex flex-row-reverse">
      
           <p>Patients</p>
                 <b-form-checkbox
                 class="custom-control-seconadry mx-1"
                 name="check-button"
                 switch
                 v-model="check"
                 @click="toggleView(check)"
               />
            <p>Cases</p>
             
        </div>
      -->
           
     
  
 

  
    <Cases :logs ="true"></Cases>
    </div>
</template>

<script>
    import Patients from "@/views/patients/components/patients";
     import Cases from "@/views/cases/components/cases";
import { BCard, BRow, BFormCheckbox, BFormCheckboxGroup,BCol,BFormDatepicker 
, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
 BPagination, BOverlay, BCardHeader, BCardBody, BModal, VBModal, BPopover, BFormGroup, BForm } from 'bootstrap-vue';
import { mapGetters, mapActions } from 'vuex';
    export default {
        name: "index",
       components: {
            Cases,
            Patients,
    BFormCheckbox,
    BFormCheckboxGroup,
  
  }
    
    , data() {
    return {
        check : false,
    }},
    
    methods:{

        toggleView(check)
        {
            this.check = !this.check;
        }

   
    }}
</script>

<style scoped>

</style>